/**
 * 管理员支付相关 API
 */
import { fetch, post, postForm } from 'api/base/axios'
import { db_time_to_local_fmt } from 'utils/timeutils.js'

const URL = {
  listUrl: '/pay/admin/list/',
  confirmUrl: '/pay/admin/confirm/',
  deleteUrl: '/pay/admin/delete/',
  exportTaskCreateUrl: '/pay/admin/export/',
  exportTaskQueryUrl: '/pay/admin/export/query/',
  exportTaskDownloadUrl: '/pay/admin/export/download/',
};

export const PAYMENT_STATE_UNPAY = 0x01;

export function listPayment (form) {
  return post(URL.listUrl, form)
}

export function createExportPaymentTask(form) {
  return post(URL.exportTaskCreateUrl, form)
}

export function queryExportPaymentTask() {
  return fetch(URL.exportTaskQueryUrl)
}

export function confirmPaymentCashed (form) {

  let frm_data = new FormData();
  frm_data.append('payment_sn', form.payment_sn);
  frm_data.append('payment_uuid', form.payment_uuid);
  frm_data.append('type', form.type);
  frm_data.append('desc', form.desc);

  if (form.img.length > 0) {

    for (let file_index in form.img) {
      let file_instance = form.img[file_index].raw;
      frm_data.append('files', file_instance, file_instance.name);
    }

  }

  return postForm(URL.confirmUrl, frm_data)
}

export function deletePayment(uuid_in) {
  return post(URL.deleteUrl, {
    'payment_uuid_in': uuid_in,
  })
}

export function fmtPaymentInfo (item) {

  let type_text = '';
  switch(item.type){
    case 1: {
      type_text= '支付宝';
    } break;
    case 2: {
      type_text= '微信支付';
    } break;
    case 3: {
      type_text= '线下支付';
    } break;
    case 4: {
      type_text= '无需支付';
    } break;
  }
  item.type_text = type_text;


  let state_text = '';
  switch(item.state){
    case -0x01: {
      state_text= '已关闭';
    } break;
    case -0x02: {
      state_text= '已退款';
    } break;
    case 1: {
      state_text= '待收款';
    } break;
    case 2: {
      state_text= '已收款';
    } break;
  }
  item.state_text = state_text;

  item.payed_at_fmt = item.payed_at ? db_time_to_local_fmt(item.payed_at) : '-';
  item.order_count = item.orders ? item.orders.length : 0;

  if (item.payment_offline) {
    item.payment_offline.created_at = db_time_to_local_fmt(item.payment_offline.created_at);
  }


  if (item.user_info) {

    let job_zh = '';
    switch (item.user_info.job) {
      case 'office': {
        job_zh = '职员';
      } break;
      case 'student': {
        job_zh = '学生';
      } break;
      case 'teacher': {
        job_zh = '教师';
      } break;
    }

    item.user_info.job_zh = job_zh;

  }


  return item

}

