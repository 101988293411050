<template>
  <div class="container_wrap">
    <el-result :icon="result_icon" :title="result_msg">

      <template #extra>

        <el-table :data="payment_info.orders" v-if="method==='payment'">
          <el-table-column prop="order_sn" label="订单编号"></el-table-column>
          <el-table-column prop="order_amount" label="支付金额" width="120">
            <template #default="scope">
              ¥ {{ scope.row.order_amount }}
            </template>
          </el-table-column>
          <el-table-column prop="payment_type_text" label="支付方式" width="120"></el-table-column>
          <el-table-column prop="payment_sn" label="支付编号"></el-table-column>

          <el-table-column prop="payed_auto_time" :label="table_col_name" ></el-table-column>

          <el-table-column prop="payment_state_text" label="支付状态" width="120"></el-table-column>
        </el-table>

        <el-table :data="[order_info]" v-if="method==='single'">
          <el-table-column prop="order_sn" label="订单编号"></el-table-column>
          <el-table-column prop="created_at_local" label="下单时间"></el-table-column>
          <el-table-column prop="state_text" label="订单状态" width="120"></el-table-column>
        </el-table>

        <el-button type="danger" @click="resultBtnAction"
                   :style="method==='payment' && is_payment_offline ? 'margin-top: 25px;margin-left:110px;': 'margin-top: 25px;'">{{ btn_text }}</el-button>

        <el-button type="text" v-if="method==='payment' && is_payment_offline"
                   @click="reselectPaymentMethod"
                   style="margin-top: 25px; color:#999; font-size: 12px;">重新选择支付方式
        </el-button>
        <br>

        <div class="code_kf">
          <img :src="require('@/assets/img/code_kf.png')" />
          <span>扫码添加客服微信</span>
        </div>

      </template>
    </el-result>
  </div>
</template>

<script>
  import {detailOrder, fmtUserOrderInfo} from 'api/settlement/order/user'
  import { db_time_to_local_fmt } from 'utils/timeutils'
  import {fmtPaymentInfo} from 'api/settlement/payment/admin'
  import {queryPayment, reselectPreparePayment} from 'api/settlement/payment/user'
  export default {
    data() {
      return {
        method: 'single',
        btn_text: '查看我的订单',
        btn_go_url: '/center/order',
        result_icon: '',
        result_msg: '',
        order_uuid: '',
        order_info: null,
        payment_uuid: '',
        is_payment_offline: false,
        payment_info: {
            orders: []
        },
        table_col_name: '',
        dashboard_base_url: null,
      }
    },
    created() {

      const that = this;

      if (this.$route.params.method != undefined && this.$route.params.method.length > 0) {
        this.method = this.$route.params.method;
      }

      if (!['single', 'payment'].includes(this.method)) {
        this.$alert('非法操作', '标题名称', {
          confirmButtonText: '确定',
          callback: action => {
            that.goPath('/');
          }
        });
        return;
      }

      if (this.$route.params.order_uuid != undefined) {

        switch (this.method) {
          case 'single': { // 单个订单结果
            this.order_uuid = this.$route.params.order_uuid;
            this.loadOrderInfo();
          } break;
          case 'payment': { // 支付结果
            this.payment_uuid = this.$route.params.order_uuid;
            this.loadPaymentInfo();
          } break;
        }

      }

    },
    methods: {
      async loadOrderInfo(){

        const that = this;
        await detailOrder(this.order_uuid).then(result => {

          if (result.succeed) {

            that.dashboard_base_url = result.dashboard_base_url;

            let filled_order_info = fmtUserOrderInfo(result.order_info);

            console.log(filled_order_info);

            that.order_info = filled_order_info;

            switch (filled_order_info.state_text) {
              case '已取消' : {
                that.result_icon = 'error';
                that.result_msg = '订单已取消，使用的优惠券已返还！';
              } break;
              case '已关闭' : {
                that.result_icon = 'error';
                that.result_msg = '订单因逾期未支付已自动关闭，订单中使用的优惠券已返还！';
              } break;
            }


            that.btn_go_url = '/center/order';
            that.btn_text = '查看我的订单';

          } else {
            that.$alert(`${result.error_msg}`, '提示', {
              confirmButtonText: '确定'
            });
          }

        }).catch(err => {
          that.$alert(`${err}`, '提示', {
            confirmButtonText: '确定'
          });
        });

      },
      async loadPaymentInfo(){

        const that = this;

        await queryPayment(this.payment_uuid).then(result => {

          if (result.succeed) {

            let payment_instance = fmtPaymentInfo(result.instance);
            let vip_order = false;

            payment_instance.orders.forEach(item=>{

                if(item.type === 2){
                    vip_order = true;
                }

                item.payment_type_text = payment_instance.type_text;
                item.payment_sn = payment_instance.payment_sn;
                item.payment_state_text = payment_instance.state_text;
                item.payed_auto_time = item.payed_at ? db_time_to_local_fmt(item.payed_at) : db_time_to_local_fmt(item.created_at) ;
            });

            console.log(payment_instance);


            switch(payment_instance.state_text){
              case '已收款' : {

                that.payment_info = payment_instance;

                that.result_icon = 'success';
                that.result_msg = '支付成功，订单已完成！';

                if (vip_order) {
                  that.btn_go_url = '/center/card/vip_card';
                  that.btn_text = '查看我的会员卡';
                } else {
                  that.btn_go_url = '/center/course';
                  that.btn_text = '查看我的课程';
                }


                that.table_col_name = '支付时间';

              } break;
              case '待收款' : {

                that.payment_info = payment_instance;

                that.result_icon = 'success';
                that.result_msg = '提交成功，请联系客服完成付款！';

                that.btn_go_url = '/center/order';
                that.btn_text = '查看我的订单';

                that.table_col_name = '下单时间';

                that.is_payment_offline = true;

              } break;
              default: {

                that.$alert(`暂不支持当前操作`, '提示', {
                  confirmButtonText: '确定',
                  callback: action => {
                    that.goPath('/');
                  }
                });
              }
                break;
            }


          } else {
            that.$alert(`${result.error_msg}`, '提示', {
              confirmButtonText: '确定'
            });
          }

        }).catch(err => {

          that.$alert(`${err}`, '提示', {
            confirmButtonText: '确定'
          });

        });

      },
      async reselectPaymentMethod(){

        const that = this;
        await reselectPreparePayment(this.payment_uuid).then(result => {

          if (result.succeed) {

              switch(result.payment_method){
                case 'single': {
                    that.goPath(`/order/pay/${result.order_uuid_list[0]}/`)
                } break;
                case 'mixed': {
                    that.goPath(`/order/pay/mixed/${result.confirm_key}/`)
                } break;
              }


          } else {
            that.$alert(`${result.error_msg}`, '提示', {
              confirmButtonText: '确定'
            });
          }

        }).catch(err => {

          that.$alert(`${err}`, '提示', {
            confirmButtonText: '确定'
          });

        });
      },
      resultBtnAction(){
        this.goPath(this.btn_go_url);
      },
      goPath(path) {
        this.$router.push(path);
      },
    }
  }
</script>

<style lang="scss" scoped>
  :deep(.el-result) {
    padding-top:80px;
    .icon-error {
      fill: #f51c21;
    }
    .el-result__icon {
      svg {
        width:80px;
        height:80px;
      }
    }
    .el-result__title {
      p {
        color:#666;
      }
    }
    .el-result__extra {
      width:100%;
      margin-top:50px;
      .el-button {
        margin-top:80px;
      }
    }
  }
  .code_kf {
    width:150px;
    background:#f2f2f2;
    padding:10px;
    margin:25px auto 0;
    > img {
      width:100%;
      margin-bottom:10px;
    }
    > span {
      color:#3fb3e0;
    }
  }
</style>
